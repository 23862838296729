import {setToken,postRequest,getRequest} from './apiService';
import {planList,getPlan,createPlan,updatePlan,deletePlan} from '../values'

/** SERVICE METHOD FOR GETTING ALL CATEGORY INFORMATION FROM API SERVER  */
export const getAllPlans = async () =>{
    try{
        setToken(localStorage.getItem('userToken'));
        const responseData = await getRequest(planList);

        console.log('inside product adapter , response data = ', responseData)   

        if(responseData.status === 200){
            return responseData.data.data;
        }
        else{
            return [];
        }

         
    }
    catch(error){
        return [];
    }
}

/** SERVICE METHOD FOR GETTING DETAIL INFORMATION ABOUT A SPECIFIC CATEGORY BY ID  */
export const getPlanDetail = async (id) =>{
    try{
        setToken(localStorage.getItem('userToken'));
        const responseData = await postRequest( getPlan , {_id:id});

        console.log('inside get product , response data = ', responseData)    

        if(responseData.status === 200){
            return responseData.data.data;
        }
        else{
            return {};
        }

        
    }
    catch(error){
        return {};
    }
}

/** SERVICE METHOD FOR SAVING CATEGORY TO API SERVER */
export const savePlan = async(plan)=>{
    try{

        setToken(localStorage.getItem('userToken'));
        const responseData = await postRequest(plan._id ? updatePlan : createPlan , plan) 

        console.log('inside update product , response data = ', responseData)

        if(responseData.status === 200){
            return responseData.data.data;
        }
        else{
            return responseData.data;
        }

    }
    catch(error){
        return {};
    }
}

/** SERVICE METHOD TO DELETE A CATEGORY */
export const removePlan = async (id) =>{
    try{
        setToken(localStorage.getItem('userToken'));
        const responseData = await postRequest( deletePlan , {_id : id})

        console.log('inside update product , response data = ', responseData) 

        if(responseData.status === 200){
            return responseData.data.data;
        }
        else{
            return responseData.data;
        }

    }
    catch(error){
        return {};
    }
}