import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    IconButton,
    Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {useState,useEffect} from 'react'

const ConfirmDialog = (props) => {

    const [showDialogue,setShowDialogue] = useState(false);

    useEffect(()=>{
        console.log('inside use effect props value = ', props.show);
        setShowDialogue(Boolean(props.show));
    },[props.show])

    return (
        <Dialog open={showDialogue} onClose={()=>{}} maxWidth="sm" fullWidth>
            <DialogTitle>Delete the product !!!!</DialogTitle>
            <Box position="absolute" top={0} right={0}>
                <IconButton>
                    <Close onClick={props.closeAction}/>
                </IconButton>
            </Box>
            <DialogContent>
                <Typography>Are you sure to delete the product ? </Typography>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={props.closeAction}>
                    Cancel
                </Button>
                <Button color="secondary" variant="contained" onClick={props.deleteAction}>
                    DELETE
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;