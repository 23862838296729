import { useState , useEffect} from "react";
import { Link , useHistory } from "react-router-dom";
import "./Navbar.scss";
import {logoutUser} from '../../adapters/loginAdapter'
import {useLoginContext} from '../../contexts/LoginContext';

const Navbar = ({isLoggedin,changeLoginStatus}) => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const history = useHistory();
  const loginContextValue = useLoginContext();


  const handleClick = () => {
    setMobileMenu(!mobileMenu);
  };

  const userLogOut = async () =>{
    const loginStatus = await logoutUser();
    loginContextValue.changeLoginStatus(loginStatus);
    history.push("/")
  }

  return (
    <div className="navbar-area">
      <div className="container">
        <div className="row justify-content-lg-between align-items-center">
          <div className="col-lg-2 col-3">
            <div className="logo">
              <a href="#0"> Logo </a>
            </div>
          </div>
          <div className="col-lg-10 col-9">
            <div className="menuWrapper">
              {loginContextValue.loggedIn ?
              <nav>

                <ul className={mobileMenu ? "active" : ""}>
                  <li>
                    <Link to="/"> Home </Link>
                  </li>
                  <li>
                    <Link to="/categoryList"> Categories </Link>
                  </li>
                  <li>
                    <Link to="/tagList"> Tags </Link>
                  </li>
                  <li>
                    <Link to="/productList"> Product List </Link>
                  </li>
                  {/* <li>
                    <Link to="/accountSettings"> Account Settings </Link>
                  </li> */}
                  <li>
                    <Link to="/planList"> Plan List </Link>
                  </li>

                  <li>
                    <Link to="/userList"> User List </Link>
                  </li>
                </ul>
                
              </nav> : <></>}
              <div className="navbar-btn">
                <ul>
                  {!loginContextValue.loggedIn ? 
                    <li>
                      <Link to="/login" className="main-btn">
                        Log in
                      </Link>
                    </li>
                  :
                    <li>
                      <button className="main-btn" onClick={userLogOut}>Log out</button>
                    </li>
                  }
                </ul>
              </div>
              <div className="mobile-menu-btn d-flex d-lg-none">
                <button onClick={handleClick}>
                  <i className="lni lni-menu"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
