import "../ProductList/ProductList.scss";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { getAllTags, removeTag } from '../../adapters/tagAdapter';
import { getAllCategories , getCategorizedTags} from '../../adapters/categoryAdapter'

const TagList = () => {

    const [tags, setTags] = useState([])
    const [categoyList, setCategoryList] = useState([])
    const [selectedCatgeory, setSelectedCategory] = useState("")

    useEffect(() => {
        getTagData();
        getCategoryList();
    }, [])

    const getCategoryList = async() =>{
        const categories = await getAllCategories();
        if(categories && categories.length){
            setCategoryList(categories);
        }
    }

    const getCategoryTags = async(categoryId)=>{
        console.log('category id =', categoryId);
        setSelectedCategory(categoryId);
        if(categoryId){
            const tagList = await getCategorizedTags(categoryId);
            setTags(tagList);
        }
        else{
            getTagData();
        }
    }

    const getTagData = async () => {
        try {
            const fetchedTags = await getAllTags();
            setTags(fetchedTags);
        }
        catch (error) {
            console.log('error happened , error = ', error);
        }
    }

    const deleteTag = async (id) => {
        try {
            const deletedTag = await removeTag(id);
            
            if (deletedTag && deletedTag._id) {
                getTagData();
            }
            else{
                alert(`Error happended ${deletedTag.error ? deletedTag.error : ""}`)
            }
        }
        catch (error) {
            console.log('error happened , error = ', error);
        }
    }

    return (
        <>
            <div className="container">
                <div className="accessList accessList--wrapper">
                    {/** HEADER SECTION  */}
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>  {/* className="title" */}
                        <h2>Tag List</h2>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                            <div style={{ margin: "5px" }}>
                                <select value={selectedCatgeory} onChange={(e) => getCategoryTags(e.currentTarget.value)}>
                                    {/* <select> */}
                                    <option value="">All Category</option>
                                    {categoyList.map((option) => (
                                        <option value={option._id} key={option._id}>{option.title}</option>
                                    ))}
                                </select>
                            </div>
                            <Link to="/addTag" className="main-btn">
                                Create New
                            </Link>
                        </div>

                    </div>

                    

                    {/** TAG LIST TABLE */}
                    <div className="accessList__table table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <span className="w-140"> TAG Name </span>
                                    </th>
                                    <th>
                                        <span className="w-140"> Order </span>
                                    </th>
                                    <th>
                                        <span className="w-140">Date-Created</span>
                                    </th>
                                    <th>
                                        <span>Action</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {tags.map((tag) => (
                                    <TagListItem tag={tag} deleteAction={deleteTag} key={tag._id} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TagList;

const TagListItem = ({ tag, deleteAction }) => {
    return (
        <tr>
            <td>
                {tag.title}
            </td>
            <td>
                {tag.order ? tag.order : 1}
            </td>
            <td>
                {tag.createdAt.split('T')[0]}
            </td>
            <td>
                <p>
                    <Link to={{
                        pathname: "/addTag",
                        state: { tagId: tag._id ? tag._id : "" }
                    }} className="main-btn">
                        View
                    </Link>

                    <button className="main-btn" style={{ backgroundColor: "red", marginLeft: "4%" }} onClick={(e) => deleteAction(tag._id)}>
                        Delete
                    </button>
                </p>
            </td>
        </tr>
    )
}