
module.exports = {
    login           : `user/login`,
    allUsers        : `user/`,
    userInfo        : `user/getUserInfoAdmin`,
    updateUserInfo  : `user/modifyUserInfoAdmin`,
    productList     : `products/`,
    getProduct      : `products/getProduct`,
    createProduct   : `products/create`,
    updateProduct   : `products/update`,
    deleteProduct   : `products/delete`,
    tagList         : 'tags/',
    getTag          : `tags/getTag`,
    createTag       : 'tags/create',
    updateTag       : 'tags/update',
    deleteTag       : 'tags/delete',
    tagWiseProduct  : 'tags/getTagWiseProduct',
    categoryList    : `categories/`,
    getCategory     : `categories/getCategory`,
    createCategory  : `categories/create`,
    updateCategory  : `categories/update`,
    deleteCategory  : `categories/delete`,
    categoryProducts: `categories/getCategoryTags`,
    planList        : 'plans/',
    createPlan      : 'plans/create',
    getPlan         : 'plans/getPlan',
    updatePlan      : 'plans/update',
    deletePlan      : 'plans/delete'
};
