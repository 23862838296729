import "../AccountSettings/AccountSettings.scss";
import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { getPlanDetail, savePlan } from '../../adapters/planAdapter';


const AddPlan = () => {

    const location = useLocation();
    const history = useHistory();

    const [planName, setPlanName] = useState("");
    const [productId,setProductId] = useState("");
    const [downloadUrls, setDownloadUrls] = useState([]);
    const [isPlanEmpty, setIsPlanEmpty] = useState(false);
    const [planErrorMessgae, setPlanErrorMessage] = useState("");

    const [planDetail, setPlanDetail] = useState({});

    useEffect(() => {
        //setDownloadUrls(data);
        if (location.state) {
            //console.log('has location state , ', location.state.categoryId)
            getplanInfo(location.state.planId)
        }
        else {
            console.log('no plan id')
        }
    }, [])

    const getplanInfo = async (id) => {
        try {
            const planInfo = await getPlanDetail(id);
            if (planInfo) {
                setPlanDetail(planInfo);
                setPlanName(planInfo.name);
                setDownloadUrls(planInfo.downloadUrls);
                if(planInfo.productId){
                    setProductId(planInfo.productId)
                }
            }
        }
        catch (error) {
            console.log('error happened , error = ', error);
        }
    }

    const deletePlan = (indexValue) => {
        const filteredUrls = downloadUrls.filter((item, index) => index !== indexValue);
        setDownloadUrls(filteredUrls);
    }

    const addPlan = () => {
        const filteredUrls = [...downloadUrls, {
            bunnyCDNurl: "",
            displayName: "",
            accessToken: ""
        }];
        setDownloadUrls(filteredUrls);
        setIsPlanEmpty(false);
    }

    const updatePlan = (index, changedValue, propertyName) => {
        const filteredUrls = downloadUrls;
        filteredUrls[index][propertyName] = changedValue;

        setDownloadUrls(filteredUrls);
    }

    const planCreateUpdate = async (event) => {
        event.preventDefault();

        if (planName === "") {
            /** CHECK IF NAME OF THE PLAN IS EMPTY  */
            setPlanErrorMessage("Name of the plan can not be empty");
            setIsPlanEmpty(true);
        }
        else if (downloadUrls.length === 0) {
            /** CHECK IF FILE DOWNLOAD LIST IS EMPTY  */
            setPlanErrorMessage("File Download list can not be empty");
            setIsPlanEmpty(true);
        }
        else {
            try {
                const planToSave = planDetail;
                planToSave.name = planName;
                planToSave.downloadUrls = downloadUrls;
                planToSave.productId = productId;

                const savedPlan = await savePlan(planToSave);

                console.log('created tag = ', savedPlan)

                /** REDIRECT USER TO PLAN LIST PAGE IS SAVING OF PLAN IS SUCCESSFULL */
                if (savedPlan && savedPlan._id) {
                    history.push('/planList')
                }
                else {
                    setPlanErrorMessage(`Error happended , ${savedPlan.error ? savedPlan.error : ""}`);
                    setIsPlanEmpty(true);
                }
            }
            catch (error) {
                setPlanErrorMessage(`Error happended , ${error.toString()}`);
                setIsPlanEmpty(true);
            }

        }


    }

    return (
        <>
            <div className="container">
                <div className="accountSettings">
                    <div className="subtitle">
                        <h4>Create/Update Plans</h4>
                    </div>

                    <form className="accountSettings__box__info">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="single-input">
                                    <label htmlFor="email">Plan Name</label>
                                    <input
                                        type="text" name="text"
                                        placeholder="Enter name of the plan"
                                        style={{ border: "1px solid #f1eeee", borderRadius: "5px" }}
                                        value={planName}
                                        onChange={(e) => { setPlanName(e.currentTarget.value); setIsPlanEmpty(false) }}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="single-input">
                                    <label htmlFor="email">Paddle Product Id</label>
                                    <input
                                        type="text" name="text"
                                        placeholder="Enter the product id of paddle"
                                        style={{ border: "1px solid #f1eeee", borderRadius: "5px" }}
                                        value={productId}
                                        onChange={(e) => { setProductId(e.currentTarget.value); setIsPlanEmpty(false) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="accessList accessList--wrapper">
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>  {/* className="title" */}
                                <h5>File Downloads</h5>
                                <a href="#" className="main-btn" onClick={(e) => { e.preventDefault(); addPlan() }}>
                                    Add New
                                </a>
                            </div>
                            <div className="accessList__table table-responsive">
                                <br />
                                <hr />
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span className="w-140"> Bunny CDN URL </span>
                                            </th>
                                            <th>
                                                <span className="w-140">Display Name</span>
                                            </th>
                                            <th>
                                                <span className="w-140">Access Token</span>
                                            </th>
                                            <th>
                                                <span>Action</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {downloadUrls.map((item, index) => (
                                            <DownloadUrlListItem
                                                downloadUrl={item}
                                                key={index}
                                                indexValue={index}
                                                deleteAction={deletePlan}
                                                updateAction={updatePlan} />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {isPlanEmpty && <span id="planErrorText" style={{ color: "red" }} >{planErrorMessgae}<br /><br /></span>}

                        <div className="row">
                            <br />
                            <div className="col-lg-12">
                                <div className="button-wrapper">
                                    <button id="createTag" className="main-btn" onClick={planCreateUpdate}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>


                </div>
            </div>
            <br /><br />
        </>
    )
}

export default AddPlan;

const DownloadUrlListItem = ({ downloadUrl, indexValue, deleteAction, updateAction }) => {
    return (
        <tr>
            <td>
                <input
                    type="text" name="text"
                    defaultValue={downloadUrl.bunnyCDNurl}

                    onChange={(e) => { updateAction(indexValue, e.currentTarget.value, "bunnyCDNurl") }}
                    style={{ border: "1px solid #f1eeee", borderRadius: "5px" }}
                />
            </td>
            <td>
                <input
                    type="text" name="text"
                    defaultValue={downloadUrl.displayName}
                    onChange={(e) => { updateAction(indexValue, e.currentTarget.value, "displayName") }}
                    style={{ border: "1px solid #f1eeee", borderRadius: "5px" }} />
            </td>
            <td>
                <input
                    type="text" name="text"
                    defaultValue={downloadUrl.accessToken}
                    onChange={(e) => { updateAction(indexValue, e.currentTarget.value, "accessToken") }}
                    style={{ border: "1px solid #f1eeee", borderRadius: "5px" }} />
            </td>

            <td>
                <p>
                    <button className="main-btn" style={{ backgroundColor: "red", marginLeft: "4%" }} onClick={(e) => { e.preventDefault(); deleteAction(indexValue); }}>
                        Delete
                    </button>
                </p>
            </td>
        </tr>
    )
}
