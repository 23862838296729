import Pricing from "../../components/Pricing/Pricing";

const Home = () => {
  return (
    <>
      <Pricing />
    </>
  );
};

export default Home;
