import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ProductList from "./components/ProductList/ProductList";
import AccountSettings from "./components/AccountSettings/AccountSettings";
import AddTag from './components/AddTag/AddTag';
import TagList from './components/TagList/TagList';
import AddCategory from './components/AddCategory/AddCategory';
import CategoryList from './components/CategoryList/CategoryList'
import AddSnippet from "./components/AddSnippet/AddSnippet";
import AddPlan from './components/AddPlan/AddPlan';
import PlanList from './components/PlanList/PlanList';
import UserList from './components/UserList/UserList';
import UpdateUser from './components/UpdateUser/UpdateUser';
import Login from "./components/Login/Login";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home/Home";

import {LoginContextProvider} from './contexts/LoginContext';

const App = () => {

  return (
    <LoginContextProvider>
      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/productList">
            <ProductList />
          </Route>
          <Route path="/addTag">
            <AddTag />
          </Route>
          <Route path="/tagList">
            <TagList />
          </Route>
          <Route path="/addCategory">
            <AddCategory />
          </Route>
          <Route path="/categoryList">
            <CategoryList />
          </Route>
          <Route path="/accountSettings">
            <AccountSettings />
          </Route>
          <Route path="/addProduct">
            <AddSnippet />
          </Route>
          <Route path="/addPlan">
            <AddPlan />
          </Route>
          <Route path="/planList">
            <PlanList />
          </Route>
          <Route path="/userList">
            <UserList />
          </Route>
          <Route path="/updateUser">
            <UpdateUser />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </LoginContextProvider>
  );
};

export default App;
