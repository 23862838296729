import "./Login.scss";
import {useState} from 'react'
import { useHistory } from "react-router-dom";

import {loginUser} from '../../adapters/loginAdapter';
import {useLoginContext} from '../../contexts/LoginContext';


const Login = ({changeLoginStatus}) => {

  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const history = useHistory();
  const loginContextValue = useLoginContext();

  const loginButtonClick = async (event) =>{
    event.preventDefault();

    /** CHECK FOR EMPTY USER EMAIL AND PASSWORD */
    if(email === "" || password === ""){
      alert('Email or password remains empty')
    }
    
    try{
      /** PERFORM USER LOGIN WITH PROVIDED DATA  */
      const isValidated = await loginUser(email , password)

      /** CHECK IF USER IS LOGGED IN SUCCESSFULLY */
      if(isValidated){ 
        /** CHANGE GLOBAL STATE FOR LOGIN */ 
        loginContextValue.changeLoginStatus(isValidated);
        
        /** REDIRECT USER TO ACCESS LIST PAGE AFTER LOGIN */
        history.push('/productList')
      }
                    
    }
    catch(error){
      console.log('error in login , error = ', error)
    }
    
  }

  return (
    <div className="login-wrapper">
      <div className="login-content">
        <h1>Login to your account</h1>
        <p>Login to your account for a faster checkout.</p>
        <form>
          <div className="single-input">
            <label htmlFor="email">Email Address</label>
            <div className="input-field">
              <input type="email" name="email" placeholder="user@email.com" value={email} onChange={(e)=> setEmail(e.target.value)}/>
              <span className="icon">
                <i className="lni lni-envelope"></i>
              </span>
            </div>
          </div>
          <div className="single-input">
            <label htmlFor="password">Your Password</label>
            <div className="input-field">
              <input type="password" name="password" placeholder="********" value={password} onChange={(e)=> setPassword(e.target.value)}/>
              <span className="icon">
                <i className="lni lni-lock"></i>
              </span>
            </div>
          </div>
          <div className="single-input">
            <button className="forget-password">Forget Password?</button>
          </div>

          <div className="single-input">
            <button type="submit" className="main-btn" onClick={loginButtonClick}>
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
