import "../AccountSettings/AccountSettings.scss";
import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import { getCategoryDetail, saveCategory } from '../../adapters/categoryAdapter';
import {baseURL} from '../../config'

const AddCategory = () => {

    const location = useLocation();
    const history = useHistory();

    const [categoryName, setCategoryName] = useState("")
    const [categoryOrder,setCategoryOrder] = useState("")
    const [categoryDescription,setCategoryDescription] = useState("")
    const [tagSorting,setTagSorting] = useState("")
    const [base64SVG , setBase64SVG] = useState("")
    const [isImageEmpty,setIsImageEmpty] = useState(true);

    /** USING EFFECT HOOK TO LOAD ABOUT TAG DETAILS IF USER COMES FROM TAG LIST PAGE  */
    useEffect(() => {
        if (location.state) {
            //console.log('has location state , ', location.state.categoryId)
            getCategoryInfo(location.state.categoryId)
        }
        else {
            console.log('no product id')
        }

    }, [])

    const getCategoryInfo = async (id) => {
        try {
            const categoryInfo = await getCategoryDetail(id);
            if (categoryInfo) {

                console.log('inside setting of category info, info = ', categoryInfo);

                setCategoryName(categoryInfo.title);

                if(categoryInfo.order){
                    setCategoryOrder(categoryInfo.order)
                }

                if(categoryInfo.sortingOfTags){
                    setTagSorting(categoryInfo.sortingOfTags);
                }

                if(categoryInfo.description){
                    setCategoryDescription(categoryInfo.description);
                }

                /** SET PREVIEW IMAGE IF ANY IMAGE URL IS AVAILABLE FROM SERVER */
                if (categoryInfo.previewIcon && categoryInfo.previewIcon != "") {
                    const imagePreview = document.getElementById('previewImage')
                    imagePreview.style.display = "block";
                    imagePreview.setAttribute('src', `${baseURL}${categoryInfo.previewIcon}`);
                    setIsImageEmpty(false);
                }
                
                
            }
        }
        catch (error) {
            console.log('error happened , error = ', error);
        }
    }

    /** NEED TO DETECT ONLY IMAGE AND DISCARD OTHER TYPES OF FILE */
    const detectImageChange = async (event) => {
        console.log('inside image on change method ');
        event.preventDefault();

        if (event.currentTarget.files.length > 0) {
            const base64File = await toBase64(event.currentTarget.files[0]);
            setBase64SVG(base64File);
            setIsImageEmpty(false);
            //console.log(event.currentTarget.files[0]);
        }

    }

    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
    
        reader.onload = (event) => {
          /** AFTER LOADING THE FILE WITH FILE READER , SET THE SOURCE ATTRIBUTE OF IMAGE PREVIEW */
          const imagePreview = document.getElementById('previewImage')
          imagePreview.style.display = "block";
          imagePreview.setAttribute('src',event.target.result)
    
          /** RESOLVE THE PROMISE TO RETURN THE BASE64 CONVERTED VALUE */
          resolve(reader.result);
        }
        reader.onerror = error => reject(error);
      });

    const categoryCreateUpdate = async (event) => {
        event.preventDefault();
        if (categoryName && !isImageEmpty) {
            try {
                let category = { 
                    title: categoryName , 
                    order : categoryOrder , 
                    sortingOfTags : tagSorting,
                    description : categoryDescription 
                }
                if(base64SVG.length > 0){
                    category = {...category , previewIcon : base64SVG};
                }
                if (location.state) {
                    /** CONCATENATE WITH CATEGORY ID FROM LOCATION HOOK FETCHED FROM REACT ROUTER DOM */
                    category = { ...category, _id: location.state.categoryId }
                }
                const createdCategory = await saveCategory(category);

                console.log('created tag = ', createdCategory)

                /** REDIRECT USER TO PRODUCT LIST PAGE IS SAVING OF PRODUCT IS SUCCESSFULL */
                if (createdCategory && createdCategory._id) {
                    history.push('/categoryList')
                }
                else{
                    if(createdCategory.error && createdCategory.error.includes("duplicate key")){
                        alert(`Error happended , Tag Name already exist, try with a different name for tag`)
                    }
                    else{
                        alert(`Error happended , ${createdCategory.error ? createdCategory.error : ""}`)
                    }
                }
            }
            catch (error) {
                console.log('error happened on saving category')
            }
        }
        else {
            alert('category name can not be empty')
        }
    }

    return (
        <>
            <div className="container">
                <div className="accountSettings">

                    <div className="accountSettings__box">
                        <div className="subtitle">
                            <h4>Create Category</h4>
                        </div>
                        <form className="accountSettings__box__info">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="single-input">
                                        <label htmlFor="email">Category Name</label>
                                        <input type="text" name="text" placeholder="Enter name of the category"
                                            value={categoryName}
                                            onChange={(e) => setCategoryName(e.currentTarget.value)} />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="single-input">
                                        <label htmlFor="email">Order</label>
                                        <input type="number" name="text" placeholder="Enter order of the category"
                                            value={categoryOrder}
                                            onChange={(e) => setCategoryOrder(e.currentTarget.value)} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="single-input">
                                        <label htmlFor="email">Description</label>
                                        <input type="text" name="text" placeholder="Enter description of the category"
                                            value={categoryDescription}
                                            onChange={(e) => setCategoryDescription(e.currentTarget.value)} />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="single-input">
                                        <label htmlFor="email">Sort Tags by</label>
                                        <select value={tagSorting} onChange={(e) => setTagSorting(e.currentTarget.value)}>
                                            <option value="">Select type of sorting</option>
                                            <option value="1">Alphabetic order</option>
                                            <option value="2">Given order value</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="preview-image">
                                        <h3>SVG Icon</h3>
                                        <div className="file-upload">
                                            <input type="file" name="previewImage" onChange={detectImageChange} />
                                            <label htmlFor="previewImage">
                                                <i className="lni lni-upload" style={{ zIndex: 2 }}></i>
                                                <span style={{ zIndex: 2 }}> Upload Svg Icon </span>

                                                <img id="previewImage" src="" alt="preview Image" width="348" height="198" style={{ position: "inherit", display: "none" }} />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                
                            </div>

                            <div className="row">
                                <br/>
                                <div className="col-lg-12">
                                    <div className="button-wrapper">
                                        <button id="createTag" className="main-btn" onClick={categoryCreateUpdate}>
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AddCategory;