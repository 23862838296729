import "./Pricing.scss";

function Pricing() {
  return (
    <div className="pricing">
      <div className="container">
        <div className="pricing__title">
          <h1>Pricing</h1>
          <h2>Choose Your Plan</h2>
        </div>

        <div className="pricing__table table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th></th>
                <th>
                  <h5 className="planName">Free Forever</h5>
                  <h2>
                    <span className="price"> $0.00</span>
                  </h2>
                  <button className="main-btn choose-btn">Choose</button>
                </th>
                <th>
                  <h5 className="planName">Starter</h5>
                  <h2>
                    <span className="price"> $49.99</span>
                  </h2>
                  <button className="main-btn">Choose</button>
                </th>
                <th>
                  <h5 className="planName">Business</h5>
                  <h2>
                    <span className="price"> $99.99</span>
                  </h2>
                  <button className="main-btn border-btn">Contact Us</button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>Accounts</p>
                </td>
                <td>
                  <p>2</p>
                </td>
                <td>
                  <p>20</p>
                </td>
                <td>
                  <p>200</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Space</p>
                </td>
                <td>
                  <p>Up to 5 GB</p>
                </td>
                <td>
                  <p>Up to 50 GB</p>
                </td>
                <td>
                  <p>Up to 500 GB</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>24/7 Support</p>
                </td>
                <td>
                  <p>
                    <i className="lni lni-checkmark"></i>
                  </p>
                </td>
                <td>
                  <p>
                    <i className="lni lni-checkmark"></i>
                  </p>
                </td>
                <td>
                  <p>
                    <i className="lni lni-checkmark"></i>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Premium Support</p>
                </td>
                <td>
                  <p>
                    <i className="lni lni-close"></i>
                  </p>
                </td>
                <td>
                  <p>
                    <i className="lni lni-checkmark"></i>
                  </p>
                </td>
                <td>
                  <p>
                    <i className="lni lni-checkmark"></i>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Monthly Maintenance</p>
                </td>
                <td>
                  <p>
                    <i className="lni lni-close"></i>
                  </p>
                </td>
                <td>
                  <p>
                    <i className="lni lni-checkmark"></i>
                  </p>
                </td>
                <td>
                  <p>
                    <i className="lni lni-checkmark"></i>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Advance Maintenance</p>
                </td>
                <td>
                  <p>
                    <i className="lni lni-close"></i>
                  </p>
                </td>
                <td>
                  <p>
                    <i className="lni lni-close"></i>
                  </p>
                </td>
                <td>
                  <p>
                    <i className="lni lni-checkmark"></i>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
