import {setToken,postRequest,getRequest} from './apiService';
import {productList , getProduct , createProduct, updateProduct , deleteProduct} from '../values'

/** SERVICE METHOD FOR GETTING ALL PRODUCT INFORMATION FROM API SERVER  */
export const getAllProduct = async () =>{
    try{
        setToken(localStorage.getItem('userToken'));
        const responseData = await getRequest(productList);

        if(responseData.status === 200){
            return responseData.data.data;
        }

        console.log('inside product adapter , response data = ', responseData)    
    }
    catch(error){
        return [];
    }
}

/** SERVICE METHOD FOR GETTING DETAIL INFORMATION ABOUT A SPECIFIC PRODUCT BY ID  */
export const getProductDetail = async (id) =>{
    try{
        setToken(localStorage.getItem('userToken'));
        const responseData = await postRequest( getProduct , {_id:id});

        if(responseData.status === 200){
            return responseData.data.data;
        }

        console.log('inside get product , response data = ', responseData)    
    }
    catch(error){
        return {};
    }
}

/** SERVICE METHOD FOR SAVING PRODUCT INFORMATION TO API SERVER */
export const saveProduct = async(product)=>{
    try{
        setToken(localStorage.getItem('userToken'));
        const responseData = await postRequest(product._id ? updateProduct : createProduct , product)

        if(responseData.status === 200){
            return responseData.data.data;
        }
        else{
            return responseData.data;
        }

        console.log('inside update product , response data = ', responseData)   
    }
    catch(error){
        return {};
    }
}

export const removeProduct = async (id) =>{
    try{
        setToken(localStorage.getItem('userToken'));
        const responseData = await postRequest( deleteProduct , {_id : id})

        if(responseData.status === 200){
            return responseData.data.data;
        }

        console.log('inside update product , response data = ', responseData)   
    }
    catch(error){
        return {};
    }
}