import "./AccountSettings.scss";

const AccountSettings = () => {
  return (
    <>
      <div className="container">
        <div className="accountSettings">
          <div className="title">
            <h2>Account Settings</h2>
          </div>
          <div className="accountSettings__box">
            <div className="subtitle">
              <h4>User Information</h4>
            </div>
            <form className="accountSettings__box__info">
              <div className="row">
                <div className="col-md-6">
                  <div className="single-input">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="single-input">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="single-input">
                    <label htmlFor="companyName">Company Name</label>
                    <input
                      type="text"
                      name="companyName"
                      placeholder="Company Name"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="single-input">
                    <label htmlFor="role">Role</label>
                    <input type="text" name="role" placeholder="Role" />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="button-wrapper">
                    <button type="submit" className="main-btn">
                      SAVE
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="accountSettings__box">
            <div className="subtitle">
              <h4>Email Address</h4>
            </div>
            <form className="accountSettings__box__info">
              <div className="row">
                <div className="col-md-6">
                  <div className="single-input">
                    <label htmlFor="email">Email Address</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="user@example.com"
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="button-wrapper">
                    <button type="submit" className="main-btn">
                      Change Email Address
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="accountSettings__box">
            <div className="subtitle">
              <h4>Password</h4>
            </div>
            <form className="accountSettings__box__info">
              <div className="row">
                <div className="col-md-6">
                  <div className="single-input">
                    <label htmlFor="newPassword">New Password</label>
                    <input
                      type="password"
                      name="newPassword"
                      placeholder="New Password"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="single-input">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="button-wrapper">
                    <button type="submit" className="main-btn">
                      Change Email Address
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSettings;
