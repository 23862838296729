import {useState, useEffect} from 'react';
import { Link } from 'react-router-dom'
import {getAllPlans,removePlan} from '../../adapters/planAdapter'

const PlanList = () =>{

    const[planList,setPlanList] = useState([]);

    useEffect(()=>{
        getPlanData();
    },[])

    const getPlanData = async () =>{
        try {
            const fetchedPlans = await getAllPlans();
            setPlanList(fetchedPlans);
        }
        catch (error) {
            console.log('error happened , error = ', error);
        }
    }

    const deletePlan = async (id) => {
        try {
            const deletedPlan = await removePlan(id);
            
            if (deletedPlan && deletedPlan._id) {
                getPlanData();
            }
            else{
                alert(`Error happened ${deletedPlan.error ? deletedPlan.error : ""}`)
            }
        }
        catch (error) {
            console.log('error happened , error = ', error);
        }
    }

    return (
        <>
            <div className="container">
                <div className="accessList accessList--wrapper">
                    {/** HEADER SECTION  */}
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>  {/* className="title" */}
                        <h2>List of Plans</h2>
                        <Link to="/addPlan" className="main-btn">
                            Create New 
                        </Link>
                    </div>

                    

                    {/** TAG LIST TABLE */}
                    <div className="accessList__table table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <span className="w-140"> Category Name </span>
                                    </th>
                                    <th>
                                        <span className="w-140">Date-Created</span>
                                    </th>
                                    <th>
                                        <span>Action</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {planList.map((plan) => (
                                    <PlanListItem plan={plan} deleteAction={deletePlan} key={plan._id} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PlanList;

const PlanListItem = ({ plan, deleteAction }) => {
    return (
        <tr>
            <td>
                {plan.name}
            </td>
            <td>
                {plan.createdAt.split('T')[0]}
            </td>
            <td>
                <p>
                    <Link to={{
                        pathname: "/addPlan",
                        state: { planId: plan._id ? plan._id : "" }
                    }} className="main-btn">
                        View
                    </Link>

                    <button className="main-btn" style={{ backgroundColor: "red", marginLeft: "4%" }} onClick={(e) => deleteAction(plan._id)}>
                        Delete
                    </button>
                </p>
            </td>
        </tr>
    )
}
